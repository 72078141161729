import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faShoppingBag, faBars } from '@fortawesome/free-solid-svg-icons';

import logo from '../images/logo-simple-white.svg';



const Navbar = ({ siteTitle }) => {
  const [ open, setOpen ] = useState(false);

  return (
    <nav className={open ? 'open' : ''}>
      <Link to="/" className="logo-link">
        <img className="logo" src={logo} alt="logo" />
      </Link>

      <div className="menu-link">
        <Link to="/histoire">Histoire</Link>
        <Link to="/la-maison">L'esprit de la maison</Link>
        <Link to="/les-inseparables">Les inséparables</Link>
        <Link to="/instagram">Instragram</Link>
        <Link to="/shop">Eshop</Link>
      </div>
  
      <div className="nav-right">
        {/* <Link to="/histoire"><FontAwesomeIcon icon={faSearch} /></Link>
          <Link to="/histoire"><FontAwesomeIcon icon={faUser} /></Link>
          <Link to="/histoire"><FontAwesomeIcon icon={faShoppingBag} /></Link> */}
      </div>
      <Link
        to="#"
        className="menu-button"
        onClick={(e) => {
          e.preventDefault();
          setOpen((prevValue) => !prevValue);
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </Link>
    </nav>
  );
};

Navbar.propTypes = {
  siteTitle: PropTypes.string,
};

Navbar.defaultProps = {
  siteTitle: ``,
};

export default Navbar;
