import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const homeImage = graphql`
  fragment homeImage on File {
    childImageSharp {
      fluid(maxHeight: 500, quality: 90) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
  }
`;

const HomeImage = ({ image }) => {
  const data = useStaticQuery(graphql`
    query {
      ambiance: file(relativePath: { eq: "ambiance-inseparables.jpeg" }) {
        ...homeImage
      }
      ambiance2: file(relativePath: { eq: "ambiance2-inseparables.jpeg" }) {
        ...homeImage
      }
      braceletProfil: file(relativePath: { eq: "products/bracelet-profil.jpeg" }) {
        ...homeImage
      }
      braceletProfilFerme: file(relativePath: { eq: "products/bracelet-profil-ferme-argent.jpeg" }) {
        ...homeImage
      }
      insta: file(relativePath: { eq: "insta.jpeg" }) {
        ...homeImage
      }
      chloe: file(relativePath: { eq: "chloe.jpeg" }) {
        ...homeImage
      }
      dessin: file(relativePath: { eq: "home-drawing.jpeg" }) {
        ...homeImage
      }
      artisanat1: file(relativePath: { eq: "artisanat/1.jpeg" }) {
        ...homeImage
      }
      artisanat2: file(relativePath: { eq: "artisanat/2.jpeg" }) {
        ...homeImage
      }
      artisanat3: file(relativePath: { eq: "artisanat/3.jpeg" }) {
        ...homeImage
      }
      artisanat4: file(relativePath: { eq: "artisanat/4.jpeg" }) {
        ...homeImage
      }
    }
  `);

  const fluid = data[image].childImageSharp.fluid;
  return <Img fluid={fluid} style={{ maxWidth: fluid.presentationWidth, margin: '0 auto'}}/>;
};

export default HomeImage;
